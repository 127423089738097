import React from "react";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { MdPayment } from "react-icons/md";

export default function BottomNav({ color, color1, color2, color3 }) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white">
      <div className="flex justify-between items-center mx-4">
        <Link to="/homedashboard">
          <div className={color ? color + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <AiFillHome className="text-4xl" />
            <p>Home</p>
          </div>
        </Link>
        <Link to="/appointment">
          <div className={color1 ? color1 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <FaCalendarAlt className="text-4xl" />
            <p>Appointment</p>
          </div>
        </Link>
        <Link to="/queue-barcode">
          <div className={color2 ? color2 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <FaUsers className="text-4xl" />
            <p>Queue</p>
          </div>
        </Link>
        <Link to="/payment">
          <div className={color3 ? color3 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <MdPayment className="text-4xl" />
            <p>Transactions</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
