import React from "react";
import BottomNav from "../BottomNav";
import TopBar from "../TopBar";
import Scanner from "./Scanner";
import Scanner2 from "./Scanner2";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import Scanner3 from "./Scanner3";

export default function QueueBarcode() {
    const [start, setStart] = React.useState(false)
    const [scanResult, setScanResult] = React.useState('');

    const startStop = () => {
        setStart(!start)
    }
    const handleScanResult = (data) => {
        setScanResult(data); 
    };

    return (
        <div className="h-screen ">
            <TopBar title={"Queue"} url={'/homedashboard'} />
            <div className="flex flex-col items-center justify-center mt-16">
                <p className="text-xl font-medium">
                    Please place the QRcode inside the area
                </p>
                <div>
                    <p className="mt-3 text-center">Click "Start" to initiate automatic scanning and "Stop" to terminate the scanning process.</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-10">
               
                <button onClick={startStop} className="bg-primary100 text-white p-2 rounded-md w-3/6 mb-4">
                    {start ? "Stop" : "Start"}
                    {start ? <i className="fa fa-stop ml-2"></i> : <i className="fa fa-play ml-2"></i>}
                    {/* {start ? <BsFillPlayFill className="ml-2" /> : <BsFillPauseFill className="ml-2" />} */}
                    
                </button>
                {
            
                    start?
                        <Scanner3
                            setStart={setStart}
                            start={start}
                            onScanResult={handleScanResult} 
                        /> :
                    <img className="mt-2" src="./barcode.png" alt="" />

                }

                <p className="text-center">{scanResult ?? ''}</p>
                <a href={scanResult ?? ''} target="_blank" rel="noopener noreferrer" className={scanResult ? 'bg-primary100 text-white p-2 rounded w-2/6 text-center' : ''}> {scanResult ? 'View' : ''} </a>
            </div>

            <BottomNav color2={'text-primary100'} />
        </div>
    );
}
