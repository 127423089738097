import React, { useEffect, useState } from "react";
import { useAddToHomescreenPrompt } from "../hooks/useAddToHomescreenPrompt";
import swal from "sweetalert";

export function AddToHomeScreen() {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);

    const hide = () => setVisibleState(false);

    useEffect(() => {
        if (prompt) {
            setVisibleState(true);
        }
    }, [prompt]);

    useEffect(() => {
        const display = () => {
            swal({
                title: "Install Misas Patient App",
                text: "Would you like to install this app?",
                icon: "question",
                buttons: ["Cancel", "Install"],
            }).then((result) => {
                if (result) {
                    promptToInstall();
                }
            });
        };

        if (isVisible) {
            display();
        }
    }, [isVisible, promptToInstall]);

    if (!isVisible) {
        return null;
    }

    return null;
}
