import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

export default function AppointmentCard({ pics, name, post, time, date,mode }) {
  const location = useLocation();
  const phone = JSON.parse(localStorage.getItem("hospital_phone"))
  return (
    <div className="border-2 shadow-xl shadow-black-600 mt-5 mx-3 rounded-lg p-2">
      <Link to={mode === 'online'? '/appointment/check': ''}>
      <div className="flex justify-between items-center w-full mb-3">
        <div className="flex items-center justify-center rounded-full h-[75px] w-[75px] bg-blue-100">
          <img src={pics} height="70px" width="70px" />
        </div>
        <div className="ml-3 w-72">
          <p className="text-[18px] font-bold">{name}</p>
          <p className="text-ash100 mt-1">{post}</p>
        </div>
        <div className="bg-green-100 rounded-full p-2 flex justify-between items-center mr-2">
          {location.pathname == "/appointment-past" ? (
              <span>{ mode}</span>
          ) : (
            <a href={"tel: " + phone}>
              <MdPhoneInTalk className="text-primary100 text-3xl" />
            </a>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center w-40 bg-green-50 py-2">
          <span className="ml-3">
            <AiFillClockCircle className="text-primary100" />
          </span>
          <span className="mx-3">{time}</span>
        </div>
        <div className="flex w-40 items-center bg-green-50 py-2">
          <span className="ml-3">
            <BsFillCalendar2DateFill className="text-primary100" />
          </span>
          <span className="mx-3">{date}</span>
        </div>
        </div>
      </Link>
    </div>
  );
}
