import React from "react";
import TopBar from "../TopBar";
import { ImCheckmark } from "react-icons/im";
import { useNavigate } from "react-router-dom";

export default function QueueWait() {
    const navigate = useNavigate();
    const [show, setShow] = React.useState(true);
    setTimeout(() => {
        setShow(false);
    }, 5000);

    return (
        <div className="h-screen ">
            <TopBar title={"Queue"} url={'/queue-barcode'} />
            <div
                className="bg-primary100 max-h-[32rem] m-5 rounded-lg text-white"
                style={{ borderRadius: "20px" }}
            >
                <div className="flex flex-col items-center justify-center mt-14">
                    <div className="flex justify-center items-center mt-8">
                        <div
                            className="mx-2 mt-2"
                            style={{ height: "2px", width: 30, backgroundColor: "white" }}
                        ></div>
                        <h1 className="font-medium text-2xl mt-2">Queue Number</h1>
                        <div
                            className="mx-2 mt-2"
                            style={{ height: "2px", width: 40, backgroundColor: "white" }}
                        ></div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <h1 className="font-bold text-5xl">24</h1>
                        <p className="p-2">Wating: 23</p>
                    </div>
                    <div>
                        <p className="p-2 pb-8">
                            You have 00:12:00 mins to reach the doctor
                        </p>
                    </div>
                </div>
            </div>
            {show ? (
                <div className=" flex flex-col-reverse justify-start items-center h-96">
                    <button className="flex justify-center items-center py-2 w-80 bg-white rounded-md text-primary100 text-[20px] outline outline-primary100 outline-2 mb-8">
                        Leave the Queue
                    </button>
                </div>
            ) : (
                <div
                    className="shadow-inner shadow-black"
                    style={{ borderRadius: "20px" }}
                >
                    <div className="flex justify-center items-center mt-[3rem]">
                        <div className="w-[120px] h-[120px] bg-primary100 flex justify-center items-center rounded-full mt-4">
                            <ImCheckmark className="text-white text-[3rem]" />
                        </div>
                    </div>
                    <p className="text-[2rem] flex justify-center items-center mt-[1rem]">
                        You are next!
                    </p>
                    <p className="text-ash100 flex justify-center items-center  px-10">
                        Please proceed to see the doctor.
                    </p>

                    <div className="flex justify-center items-center">
                        <button onClick={navigate('/queue')} className="font-bold w-full py-3 bg-primary100 rounded-md text-white text-[22px] mx-5 mt-[2rem]">
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
