import React from 'react'
import Queue from '../../components/queue/Queue'



export default function QueueScreen() {
    return (
        <div> <Queue /></div>
    )
}
