import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { getOTp, verifyOtp } from "../redux/actions/userActions";
import Loading from "./Loading";

function OTP() {
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(30);
  const [message, setMessage] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state);
  const otpStore = store.verifyOtp;
  // const patient = useSelector((state) => state.createUser.data);
  const patient = useLocation().state;
  console.log('patient', patient)
  const { success, error, data } = store.getOtp;
  const { loading } = store.verifyOtp
  console.log("otp store", otpStore);
  console.log("otp sucess", success);
  console.log('store data', store.getOtp)

  // submit otp
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyOtp(patient.file_number, patient.phone_number, otp));
    setTimeout(() => {

    }, 2000);
  };

  // resend otp
  const resendOtp = () => {
    if (!patient) return navigate('/register');
    setMinutes(4);
    setSeconds(30);
    dispatch(getOTp(patient.file_number, patient.phone_number));
    localStorage.setItem('no_otp', JSON.stringify('true'))
  }

  React.useEffect(() => {
    if (localStorage.getItem('no_otp') == 'false') {
      console.log('no otp', localStorage.getItem('no_otp'))
      resendOtp()
    }

    if (otpStore.success) {
      localStorage.setItem('phone', JSON.stringify(patient.phone_number))
      localStorage.setItem('file_number', JSON.stringify(patient.file_number))
      navigate('/createpassword')
    }
    // otp timer
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);


  return (
    <div>
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
        <div className="relative bg-white px-6 pt-10 pb-5 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              {/* cancel button */}
              <div className="absolute top-4 right-4">
                <Link to="/register">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-400 hover:text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={5}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </Link>
              </div>
              {/* cancel button */}

              <div className="font-semibold text-3xl">
                <p>OTP Verification</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>
                  To verify your account, we've sent a one time password (OTP)
                  to your phone number and email.
                </p>
              </div>
              <span className="text-red-500">{error?.message} {otpStore.error ? otpStore.error : ''}
              </span>
              <span className="text-green-500">{data?.message} !
              </span>
            </div>

            <div>

              <form action="" method="post">
                <div className="flex flex-col space-y-16">
                  <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                    <OtpInput
                      value={otp}
                      onChange={
                        otp => setOtp(otp)
                      }
                      numInputs={4}
                      separator={<span></span>}
                      isInputNum={true}
                      placeholder={''}
                      containerStyle={'w-48  flex flex-row items-center justify-between mx-auto max-w-xs'}
                      inputStyle={'min-w-[40px] h-16 flex flex-col items-center justify-center text-center text-black-500 font-extrabold text-2xl outline-none rounded-xl border border-gray-400 text-lg focus:bg-gray-50 focus:ring-1 ring-blue-700'}
                    />
                  </div>

                  <div className="flex flex-col space-y-5">
                    <div>
                      {
                        loading ? <Loading /> :

                          <button className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-primary100 border-none text-white text-[18px] shadow-sm" onClick={handleSubmit}>
                            Continue
                          </button>
                      }

                    </div>

                    <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                      {/* <p>Didn't recieve the SMS?</p>{" "}
                      <button
                        onClick={resendOtp}
                        className="items-center text-yellow-500"
                      >
                        Resend code
                      </button> */}

                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      ) : (
                        <p>Didn't recieve code?</p>
                      )}

                      <button
                        disabled={seconds > 0 || minutes > 0}
                        style={{
                          color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                        }}
                        onClick={resendOtp}
                      >
                        Resend OTP
                      </button>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
}

export default OTP;
