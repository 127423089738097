import React, { useEffect, useState } from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk';
import Loading from '../../components/Loading';
import TopBar from '../../components/TopBar';
import { baseUrl } from '../../redux/actions/baseUrl';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';


export default function VideoAppointment() {
    const [roomName, setRoomName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState('');
    const location = useLocation().state;
    // console.log('locatino', location)
    const navigate = useNavigate();

    const check = async () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        }
       const {data} = await axios.get(
           `${baseUrl}/check_appointment?patient=${JSON.parse(localStorage.getItem("userInfo")).id}&id=${location}`,
            config
        );

        // console.log('data', data);
        if (data?.appointment?.length <= 0) {
            alert('You do not have any appointment today')
            swal({
                text: "You do not have any appointment today",
                title: "No Appointment",
                icon: "warning",
                buttons: ["Cancel", "OK"],
            }).then(async (confirm) => {
                if (confirm) {
                   return navigate('/homedashboard')
                } else {   
                    return navigate('/homedashboard')
                }
                });
        } else {
            setMode(data.appt_mode);
            setRoomName(data.appt_url.replace("https://meet.jit.si/", ""))
            
            // console.log('roomName', roomName)
            // console.log('Please wait !!')
        }
    } 

    const ready = async () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        }
        const { data } = await axios.get(
            `${baseUrl}/check_appointment?patient=${JSON.parse(localStorage.getItem("userInfo")).id}&id=${location}`,
            config
        );
    }

    useEffect(() => {
        check();
    }, [isLoading, mode, roomName]);
    
    if (roomName.length < 3) {
        return <div className='flex justify-center items-center flex-col'>
            <Loading />
            <div className='mt-8 flex flex-col' >
                <p className='font-bold h1 text-[22px]' >Please wait for few seconds</p>
                <p>Loading......</p>
            </div>
        </div>
    }
    return (
        <div className='h-screen' >
            <TopBar title={"Live"} url={'/homedashboard'} />
            {
                isLoading ? <div className='flex justify-center items-center flex-col'>
                    <Loading />
                    <div className='mt-8 flex flex-col' >
                        <p className='font-bold h1 text-[22px]' >Please wait for few seconds</p>
                        <p>Loading......</p>
                    </div>
                </div> :''
            }


            <JitsiMeeting
                // domain={'YOUR_DOMAIN'}
                roomName={roomName}
                configOverwrite={{
                    startWithAudioMuted: false,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    defaultLogoUrl: 'https://misas.com.ng/assets/images/logos/misaslogo.png',
                    disableDeepLinking: true, // Disable mobile app promotion banner
                    startAudioOnly: mode == 'audio' ? true : false,
                }}

                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
                    BRAND_WATERMARK_LINK: 'https://misas.com.ng/assets/images/logos/misaslogo.png',
                    DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://misas.com.ng/assets/images/logos/misaslogo.png',
                    DEFAULT_BACKGROUND: '#087F4C',
                    SHOW_BRAND_WATERMARK: false,
                    SHOW_JITSI_WATERMARK: false,
                    POLICY_LOGO: 'https://misas.com.ng/assets/images/logos/misaslogo.png',
                    PROVIDER_NAME: 'Misas',
                    HIDE_DEEP_LINKING_LOGO: true,
                    MOBILE_APP_PROMO: false,

                }}
                // spinner={<Loading/>}
                userInfo={{
                    displayName: `${JSON.parse(localStorage.getItem("userInfo"))?.firstname} ${JSON.parse(localStorage.getItem("userInfo"))?.lastname}`
                        }}
                        onApiReady={(externalApi) => {
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 6000);
                          
                        }}
                        // spinner={true}
                        getIFrameRef={(iframeRef) => { iframeRef.style.height = '90%'; }}
            />

        </div>
    )
}
