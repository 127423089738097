import React, { useEffect, useState } from "react";
import register from "../assets/register.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction } from "../redux/actions/userActions";
import axios from "axios";
import Loading from "./Loading";
import { toast } from "react-toastify";
import QrButton from "./Button/QrButton";

function FirstLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state.loginUser);
  const { loading, success, error } = store;

  const [state, setState] = useState({
    file_number: "",
    password: "",
  });
  useEffect(() => {
    if (error?.todo == "verify_otp") {
      navigate("/verify", { state: state.file_number });
    }
    if (success) {
      navigate("/homedashboard");
      toast.success("Login Successful");
      window.location.reload();
    }
  }, [success, error && error.todo]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!state.file_number || !state.password) {
      toast.error('All fields are required', {
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let patient_id = "";
    dispatch(loginUserAction(state.file_number, state.password, patient_id));
    // setState({
    //   file_number: "",
    //   password: "",
    // });
  };

  const goToQrScreen = () => {
    navigate('/qr-login')
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md  shadow-xl">
        <div
          className="h-1/2 w-[100px] relative"
          style={{
            backgroundImage: `url(${register})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "100%",
            height: "400px",
          }}
        >
          <div className="absolute bg-primary100 opacity-50 top-0 right-0 bottom-0 left-0">
            <div className="absolute bottom-5 left-1">
              <p className="text-[30px] text-white px-5">Welcome to</p>
              <p className="text-[40px] text-white font-bold px-5">
              Guardian Health
              </p>
            </div>
          </div>
        </div>
        <div className="h-1/2">
          <p className="mt-5 text-ash100 text-[18px] px-5">
            Input your Phone Number or file number to login
          </p>
          <p className="text-red-500 px-5 font-bold " >{error ? error.message : ''}</p>

          <div className="flex justify-center flex-col items-center" >
          <p className="mt-[1rem] text-[20px] font-bold px-5">Phone Number or File number</p>
              <input
            type="text"
            name="file_number"
            value={state.file_number}
            placeholder="Enter your File number"
            onChange={changeHandler}
            className="py-3 w-5/6 px-2 w-5/6 text-[16px] text-black outline outline-1 outline-green-400 rounded-sm mb-[1rem]"
          />
          <p className="text-[20px] font-bold">Password</p>
          <input
            type="password"
            placeholder="Enter your password"
            onChange={changeHandler}
            className="py-3 w-5/6 px-2 w-5/6 text-[16px] text-black outline outline-1 outline-green-400 rounded-sm mb-[2rem]"
            name="password"
            value={state.password}
          />
          </div>
          {
            loading ? <Loading /> : <div className="flex justify-center flex-col items-center mb-2">

              <button
                onClick={submitHandler}
                className="w-5/6 py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mb-[1rem]"
              >
                Log In
              </button>
              <QrButton onClick={goToQrScreen} />
            </div>
          }
          {/* </Link> */}
          <div className="mt-4 mb-4">
          <span className=" text-[16px] mt-10 ml-5">
            Don't have an account ?
          </span>{" "}
          <span className=" text-[16px] mt-10 text-primary100 font-bold">
            {" "}
            <Link to="/register" className="text-primary100 font-bold">Register</Link>
          </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstLogin;
