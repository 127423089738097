import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getRecentPayments } from '../../redux/actions/payment';
import BottomNav from '../BottomNav';
import Loading from '../Loading';
import TopBar from '../TopBar'
import Paystack from './Paystack';
import swal from "sweetalert";
import { check_token } from '../../utils';

export default function PaymentHome() {
    const dispatch = useDispatch();
    const { loading,  success, data } = useSelector(state => state.getRecentPayments);
    let user = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        amount: '',
        email: user && user.email ? user.email : '',
        phone: user && user.mobile ? user.mobile : '',
        notes: ''
    });
    const [recentPayments, setRecentPayments] = React.useState([]);
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    const handleSubmit = () => { 
        swal({
            text: "Payment will be available soon !",
            title: "Coming soon.",
            icon: "warning",
            // buttons: ["Cancel", "Yes, Delete"],
        })
    }

    useEffect(() => {
        check_token();
        dispatch(getRecentPayments(user && user.id ? user.id : ''))
        setRecentPayments(data?.recent_trans)
    }, [success])

    return (
        <div className="h-auto">
            <TopBar url={"/homedashboard"} title={'Payment'} />
            <div className="flex flex-col items-center justify-center mt-8 w-full">
                <p className='mt-4 font-bold text-xl'>Your Balance: <span className='text-green-500' > {user && user.acc_balance ? formatter.format(user.acc_balance) : ''} </span></p>
                <p className='font-bold text-2xl text-green-500 m-4'>Make payment deposit</p>
                <div className="mt-4 font-bold text-xl w-5/6">
                    <p>Amount</p>
                    <input className="border-2 px-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="5000"
                        value={state.amount}
                        onChange={(e) => setState({ ...state, amount: e.target.value })}
                    />
                </div>
                <div className="mt-4 font-bold text-xl w-5/6">
                    <p>Email <small>(..for payment receipt)</small></p>
                    <input className="border-2 px-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="example@gmail.com"
                        value={state.email}
                        onChange={(e) => setState({ ...state, email: e.target.value })}
                    />
                </div>
                <div className="mt-4 font-bold text-xl w-5/6">
                    <p>Phone Number</p>
                    <input className="border-2 px-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="5000"
                        value={state.phone}
                        onChange={(e) => setState({ ...state, phone: e.target.value })}
                    />
                </div>
                <div className="mt-4 font-bold text-xl w-5/6">
                    <label for="message" className="mb-2 font-medium dark:text-gray-400">Notes</label>
                    <textarea id="message" column="10" rows="3" className="px-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Notes..."
                        value={state.notes}
                        onChange={(e) => setState({ ...state, notes: e.target.value })}
                    ></textarea>
                </div>
                {/* <Paystack amount={state.amount} phone={state.phone} email={state.email} notes={state.notes} /> */}
                <button className="w-80 py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mt-[2rem] mb-4" onClick={handleSubmit}>
                    Pay Now !
                </button>
            </div>
            <hr className='bg-primary100 h-[10px] w-full mt-5' />
            <div className='flex items-center flex-col justify-center mt-5 mb-32'>
                <p>Past transactions </p>
                {/* table */}
                <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Type
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-gray-200">
                            {
                                loading? <Loading/>:
                                recentPayments?.length > 0 ? recentPayments && recentPayments.map((payment, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                            {new Date(payment.created).toISOString().split('T')[0]}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">{formatter.format(payment.amount)}</div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            {payment.activity_type}
                                        </span>
                                    </td>



                                </tr>

                            )) : <div className=" flex justify-center items-center">
                                            <p className="text-primary100 font-bold mt-5">No records</p>
                               
                            </div>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <BottomNav color3={'text-primary100'} />
        </div >
    )
}
