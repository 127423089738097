import React, { useEffect, useState } from "react";
import register from "../assets/register.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUserAction, getOTp } from "../redux/actions/userActions";
import Loading from "./Loading";
import HospitalList from "./HospitalList";
import { toast } from "react-toastify";
import QrButton from "./Button/QrButton";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state);
  const { loading, success, error, data } = store.createUser;
  console.log("success", success);
  console.log("store data", data);
  console.log("store error", error);
  const [state, setState] = useState({
    file_number: "",
  });

  useEffect(() => {
    if (success) {
      dispatch(getOTp(state.file_number, data.phone_number));
      navigate("/success", { state: data });
    }
    return () => {
      dispatch({ type: 'CREATE_USER_RESET' })
    };
  }, [success]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setState({
      ...state,
      [name]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!state.file_number) {
      toast.error("Please enter your misas/file number");
      return;
    }

    // if (localStorage.getItem('mayHospital') == null) {
    //   toast.error('Please Select an Hospital from the list')
    //   return;
    // }
    console.log("state", state);
    let res = dispatch(createUserAction(state.file_number));
    // setState({
    //   file_number: "",
    // });
  };

  const goToQrScreen = () => {
    navigate('/qr-register')
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md">
        <div
          className="h-1/2 w-[100px] relative"
          style={{
            backgroundImage: `url(${register})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "400px",
          }}
        >
          <div className="absolute bg-primary100 opacity-50 top-0 right-0 bottom-0 left-0">
            <div className="absolute bottom-5 left-1">
              <p className="text-[30px] text-white px-5">Welcome to</p>
              <p className="text-[40px] text-white font-bold px-5">
              Guardian Health
              </p>
            </div>
          </div>
        </div>
        <div className="h-1/2  ">
        {/* <HospitalList/> */}
          <p className="mt-5 text-ash100 text-[18px] px-5">
            Input your file number to register
          </p>
          <p className="text-red-500 px-5">{error ? error : ""}</p>
          <div className="flex items-center flex-col">
          <p className="mt-[1rem] text-[20px] font-bold px-5">File number</p>
          <input
            type="text"
            name="file_number"
            onChange={changeHandler}
            placeholder="Enter your file number"
            className="py-3 w-[95%]  mx-5 px-5 outline outline-1 outline-gray-400 rounded-sm mb-[1rem]"
          />
</div>
          {" "}
          <div className="flex justify-center items-center">
            {
              loading ? <Loading /> :
              <div className="flex justify-center flex-col items-center w-5/6">
                <button
                onClick={submitHandler}
                className=" w-[95%] py-2 bg-white rounded-md text-primary100 text-[18px] outline outline-primary100 outline-1 mt-3 mb-10"
              >
                Continue
                  </button>
                  <QrButton onClick={goToQrScreen} />

                  </div>
                
            }
          </div>

          <span className=" text-[16px] mt-10 ml-5">
            Already have an account ?
          </span>{" "}
          <span className="text-[16px] mt-10 text-primary100 font-bold">
            {" "}
            <Link to="/login" className="text-primary100 font-bold" >Login</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Register;
