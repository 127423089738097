import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAIL,
  CREATE_PASSWORD_RESET,
} from "../constants/createPasswordConstants.js";

const createPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PASSWORD_REQUEST:
      return { loading: true };
    case CREATE_PASSWORD_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CREATE_PASSWORD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case CREATE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export { createPasswordReducer };
