import React from "react";

export default function Button({ text, width, bg, textColor, handleClick }) {
    return (
        <div>
            <button
                className={
                    "w" +
                    "-" +
                    width +
                    " " +
                    bg +
                    " " +
                    textColor +
                    " " +
                    "border-2 shadow-xl shadow-black-600 py-2 rounded-lg text-[18px]"
                }
                onClick={handleClick}
            >
                {text}
            </button>
        </div>
    );
}
