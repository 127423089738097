import React from "react";
import CreatePassword from "../components/CreatePassword";

function CreatePaaswordScreen() {
  return (
    <div>
      <CreatePassword />
    </div>
  );
}

export default CreatePaaswordScreen;
