import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointmentAction, getClinicsAction } from "../../redux/actions/appointmentActions";
import Button from "../../components/Button/Button";
import TopBar from "../../components/TopBar";
import axios from "axios";
import Loading from "../../components/Loading";
import { check_token, pushNotification } from "../../utils";
import { toast } from "react-toastify";
export default function FirstAppointment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state.bookAppontment);
  const clinicStore = useSelector((state) => state.getClinicsAction);
  const { loading, success, error, data } = store;
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const [state, setState] = React.useState({
    appt_date: "",
    appt_time: "",
    clinic_name: "",
    clinic_id: "",
    notes: "",
    patient: user.id,
    location: user.location,
    appt_type: 'hospital',
    appt_mode: 'hospital',
  });
  const [clinic, setClinic] = React.useState([])
  const handleChange = (e) => {
    if (e.target.name === "clinic") {
      setState({ ...state, clinic_name: e.target.options[e.target.selectedIndex].text, clinic_id: e.target.value })
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });

  };

  const msg = (text) => {
    toast.warning(text, {
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.clinic_name === "") {
      msg("Please select a clinic");
      return;
    }
    if (state.appt_date === "") {
      msg("Please choose an appointment date");
      return;
    }
    if (state.appt_time === "") {
      msg("Please choose an appointment time");
      return;
    }
    dispatch(bookAppointmentAction(state));
  };

  useEffect(() => {
    check_token();

    if (error) {
      toast.error(error);
      dispatch({ type: "BOOK_APPOINTMENT_RESET" });
    }

    if (success) {
      pushNotification('You have successfully booked an appointment');
      dispatch({ type: "BOOK_APPOINTMENT_RESET" });
      dispatch({ type: "GET_APPOINTMENT_RESET" });
      navigate("/appointment-booked", { state: { data: state } });
    }
    setClinic(clinicStore?.data?.clinics)
    dispatch(getClinicsAction())

  }, [success])

  return (
    <div className="h-screen ">
      <TopBar title={"Appointment"} url={"/homedashboard"} />
      <div className="flex justify-between mt-5 mx-5">
        <div className="">
          <Button
            text={"Hospital"}
            textColor={"text-white"}
            bg={"bg-primary100"}
            width={"40"}
          />
        </div>
        <div className="">
          <Link to={"/appointment-online"}>
            <Button text={"Online"} width={"40"} />
          </Link>
        </div>
      </div>
      <div className="flex flex-col mt-5 mx-5">
        <div className="mt-4">

          {/* <p className="font-medium text-xl">Doctor’s Name</p> */}
          <p className="font-medium text-xl">Select Clinic</p>
          {/* Seclect list of clinics */}
          <select
            className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
            name="clinic"
            onChange={handleChange}
          >
            <option value="">Select Clinic</option>
            {clinicStore?.data?.clinics.length > 0 ? clinicStore?.data?.clinics?.map((item, index) => {
              return (
                <option key={index} value={
                  item.id
                }>{item.name}</option>
              )
            }) : <option value="">loading....</option>}

          </select>

        </div>
        <div className="mt-4">
          <p className="font-medium text-xl">Choose Appointment date</p>
          <input
            type={"date"}
            className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
            name="appt_date"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mt-[1rem] p-4">
        <p className="text-[20px] font-bold mb-2 ml-1">Choose Time</p>
        <input
          type={"time"}
          name="appt_time"
          onChange={handleChange}
          className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
        />
      </div>
      <div>
        <label htmlFor="notes" className="text-[20px] font-bold mb-2 ml-3">
          Notes
        </label>
        <div className="mt-2 p-4">
          <textarea
            rows={4}
            name="notes"
            onChange={handleChange}
            id="notes"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md outline outline-1 outline-gray p-2"
            defaultValue={""}
            placeholder="Tell us how you feel"

          />
        </div>
      </div>

      <div className="flex justify-center items-center">
        {
          loading ? <Loading /> :
            <button className=" w-full  py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mt-[2rem] mb-4" onClick={handleSubmit}>
              Submit
            </button>
        }
      </div>

    </div >
  );
}
