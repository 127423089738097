import React, { useEffect, useState } from 'react'
import TopBar from '../../components/TopBar'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import AppointmentCard from '../../components/Appointment/AppointmentCard';
import doctorPics from "../../assets/clinic.jpg";
import Button from '../../components/Button/Button';
import { checkAppointment } from '../../redux/actions/checkAppointment';
import BottomNav from '../../components/BottomNav';
import { AiFillPlayCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';


export default function CheckAppointmentDay() {
    const store = useSelector(state => state.checkAppointment);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [appointment, setAppointment] = useState([])

    const handleLive = (id) => {
        navigate('/appointment/live', {state: id})
        // console.log('first', id)
    }

    useEffect(() => {
        setAppointment(store?.data?.appointment)
        if (!store.success) {
        dispatch(checkAppointment(JSON.parse(localStorage.getItem("userInfo")).id));
        }
      
    }, [store?.success]);
  return (
      <div className='h-full'>
          <TopBar title={"Current Appointment"} url={-1} />

          <div className="mb-8 mt-4">
              <div className='flex justify-center items-center'>
                  <p className='text-primary100 m-4'>Please enter appointment room at least 5 minutes before the scheduled time</p>

              </div>
              {
                  store?.loading ? <Loading /> :
                      appointment?.length > 0 ? appointment.map((item, index) => {
                          return (
                              <>
                              <div key={index} className='mx-3 rounded-md flex flex-col justify-center align-center bg-green-400 mb-2'>
                                  <AppointmentCard
                                      pics={doctorPics}
                                      name={item.clinic_name ? item.clinic_name : "Clinic"}
                                      post={"Clinic"}
                                      date={item.appt_date}
                                      time={item.appt_time}
                                          mode={item.appt_type}
                                          key={index}
                                  />
                                     
                                      <hr/>
                            
                                      <Button text={`Enter Room (${item.appt_mode})`} bg={'bg-primary100'} textColor={'text-white'} width={'full'} handleClick={()=> handleLive(item.id)} />
                                   
                                  </div>

                                  
                                  </>
                          )
                      }) : <div className=" flex justify-center items-center">
                          <p className="text-primary100 font-bold mt-5">No active online appointment</p>
                      </div>}

          </div>

          <BottomNav color1={'text-primary100'} />
    </div>
  )
}
