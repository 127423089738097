import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import NoNotification from '../../components/notification/NoNotification'
import NotificationDay from '../../components/notification/NotificationDay'
import NotificationRow from '../../components/notification/NotificationRow'
import TopBar from '../../components/TopBar'
import { pushNotification } from '../../utils'

export default function NotificationScreen({ day, message }) {
    const store = useSelector((state) => state.getAppointmentAction);
    const [appointment, setAppointment] = React.useState(store?.data);
    const [data, setData] = React.useState([])
    const [pastData, setPastData] = React.useState([]);

    // const [show, setShow] = React.useState(false);
    const user = JSON.parse(localStorage.getItem("userInfo"));
    useEffect(() => {
        // check if appointment date is today and add to appointment array
        appointment?.upcoming?.map((item) => {
            // console.log('date', new Date().toISOString().slice(0, 10))
            // console.log('item', item.appt_date)
            if (item.appt_date === new Date().toISOString().slice(0, 10)) {
                pushNotification('Hello ' + user.firstname + ', you have an appointment today by ' + item.appt_time)
                setData((data) => [...data, item]);
                localStorage.setItem('notifications', 'true')
            } else {
                localStorage.setItem('notifications', 'false')
            }
        });

        // check if appointment date is yesterday and add to past appointment array
        appointment?.past?.map((item) => {
            if (item.appt_date === new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10)) {
                setPastData((pastData) => [...pastData, item]);
            }
        });
    }, []);

    let k = "id";

    return (
        <div className="h-screen">
            <TopBar title={'Notification'} url={'/homedashboard'} />
            {

                <>
                    <NotificationDay day={'Today'} />
                    <div className='m-5'>
                        {
                            data?.length > 0 ?
                                [...new Map(data.map(item => [item[k], item])).values()]?.map((item, index) => {
                                    return <NotificationRow key={index} message={'Hello ' + user.firstname + ', you have an appointment today by    ' + item.appt_time} />
                                })
                                :
                                <NoNotification name={JSON.parse(localStorage.getItem("userInfo"))?.firstname} />
                        }
                        {/* <NotificationRow message={'Appointment completed with Dr. James'} /> */}
                    </div>

                    <NotificationDay day={'Yesterday'} />
                    <div className='m-5'>
                        {
                            pastData?.length > 0 ?
                                [...new Map(pastData.map(item => [item[k], item])).values()]?.map((item, index) => {
                                    return <NotificationRow textColor={'text-gray-400'} key={index} message={'Hello ' + user.firstname + ', an appointment was completed yesterday.'} />
                                })
                                :
                                <NoNotification name={JSON.parse(localStorage.getItem("userInfo"))?.firstname} />
                        }
                    </div>
                </>

            }

        </div>
    )
}
