import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getOTp, verifyOtp } from "../redux/actions/userActions";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';


export default function VerifyOtp() {
    let location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useSelector((state) => state);
    const otpStore = store.verifyOtp;
    console.log('sotes', store)
    const { success, error } = store.getOtp;
    const [state, setState] = React.useState({
        file_number: location.state,
        phone_number: '',
    });
    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(4);
    const [seconds, setSeconds] = useState(30);

    console.log('location', location)
    // resend otp
    const resendOtp = () => {
        // check if input is empty
        if (!state.file_number || !state.phone_number) {
            return toast.error('Please fill all fields')
        }
        setMinutes(4);
        setSeconds(30);
        dispatch(getOTp(state.file_number, state.phone_number));
    }

    // submit otp
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(verifyOtp(state.file_number, state.phone_number, otp));

    };
    React.useEffect(() => {
        if (otpStore.success) {
            navigate('/login')
        }
        // otp timer
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [success, seconds])

    const submitHandler = (e) => {
        if (!state.phone_number) {
            return toast.error('Please fill all fields')
        }
        e.preventDefault();
        dispatch(getOTp(state.file_number, state.phone_number));
    }

    const changeHandler = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setState({
            ...state,
            [name]: value,
        });
    };

    const handleClose = () => {
        dispatch({ type: 'GET_OTP_RESET' })
        dispatch({ type: 'LOGIN_USER_RESET' })
        navigate('/')
    }
    return (
        <div className="h-screen flex flex-col justify-center items-center">
            {!success ?
                <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md  shadow-xl">
                    <div className="h-1/3 relative" >
                        <div className="absolute bg-primary100 opacity-50 top-0 right-0 bottom-0 left-0">
                            {/* cancel button */}
                            <div className="absolute top-4 right-4">

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400 hover:text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={handleClose}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={5}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>

                            </div>
                            {/* cancel button */}
                            <div className="absolute bottom-5">
                                <p className="text-[30px] text-white px-5"></p>
                                <p className="text-[25px] text-white font-bold px-5">
                                    Verify your Phone Number
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="h-1/2  ">
                        <p className="mt-5 text-ash100 text-[18px] px-5">
                            Input your file number and phone number to verify
                        </p>
                        <span className="text-red-500 px-5" >{error ? error : ''}</span>
                        <p className="mt-[1rem] text-[20px] font-bold px-5">File number</p>
                        <input
                            type="text"
                            name="file_number"
                            value={state.file_number}
                            placeholder="Enter your File number"
                            onChange={changeHandler}
                            className="py-3 w-[95%] text-[16px] text-black mx-5 px-5 outline outline-1 outline-green-400 rounded-sm mb-[2rem]"
                        />
                        <p className="mt-[1rem] text-[20px] font-bold px-5">Phone number</p>
                        <input
                            type="phone_number"
                            placeholder="Enter your phone_number"
                            onChange={changeHandler}
                            className="py-3 w-[95%] text-[16px] text-black mx-5 px-5 outline outline-1 outline-green-400 rounded-sm mb-[2rem]"
                            name="phone_number"
                            value={state.phone_number}
                        />

                        <div className="flex justify-center items-center">
                            <button
                                onClick={submitHandler}
                                className=" w-full  py-2 bg-primary100 rounded-md text-white text-[18px] mx-5 mb-[2rem]"
                            >
                                Continue
                            </button>
                        </div>
                        {/* </Link> */}

                    </div>
                </div>
                :
                <div>
                    <div className="flex flex-col items-center justify-center text-center space-y-2 m-5">
                        {/* cancel button */}
                        <div className="absolute top-4 right-4">

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400 hover:text-gray-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={handleClose}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={5}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>

                        </div>
                        {/* cancel button */}
                        <div className="font-semibold text-3xl">
                            <p>OTP Verification</p>
                        </div>
                        <div className="flex flex-row text-sm font-medium text-gray-400">
                            <p>
                                To verify your account, we've sent a one time password (OTP)
                                to your phone number.
                            </p>
                        </div>
                        <span className="text-red-500 px-5" >{otpStore.error ? otpStore.error : ''}</span>
                        {/* <span className="text-red-500">{error?.message} {otpStore.error ? otpStore.error : ''}
                        </span> */}
                        {/* <span className="text-green-500">{data?.message} !
                        </span> */}
                    </div>

                    <div className="flex flex-col space-y-16">
                        <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">

                            <OtpInput
                                value={otp}
                                onChange={
                                    otp => setOtp(otp)
                                }
                                numInputs={4}
                                separator={<span></span>}
                                isInputNum={true}
                                placeholder={''}
                                containerStyle={'w-48  flex flex-row items-center justify-between mx-auto max-w-xs'}
                                inputStyle={'min-w-[40px] h-16 flex flex-col items-center justify-center text-center text-black-500 font-extrabold text-2xl outline-none rounded-xl border border-gray-400 text-lg focus:bg-gray-50 focus:ring-1 ring-blue-700'} />
                        </div>

                        <div className="flex flex-col space-y-5">
                            <div className='flex items-center justify-center' >

                                <button className="flex flex-row items-center justify-center text-center w-80 border rounded-xl outline-none py-5 bg-primary100 border-none text-white text-[18px] shadow-sm" onClick={handleSubmit}>
                                    Continue
                                </button>

                            </div>

                            <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                                {seconds > 0 || minutes > 0 ? (
                                    <p>
                                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </p>
                                ) : (
                                    <p>Didn't recieve code?</p>
                                )}

                                <button
                                    disabled={seconds > 0 || minutes > 0}
                                    style={{
                                        color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                                    }}
                                    onClick={resendOtp}
                                >
                                    Resend OTP
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
