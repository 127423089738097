import { CREATE_PAYMENT_REQUEST, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAIL, GET_RECENT_PAYMENT_FAIL, GET_RECENT_PAYMENT_SUCCESS, GET_RECENT_PAYMENT_REQUEST } from "../constants/payment";
import axios from "axios";
import { baseUrl } from "./baseUrl";

export const createPayment = (patient, amount, reference, location, notes) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_PAYMENT_REQUEST,
        });
        // const payment_data = {
        //     patient,
        //     amount,
        //     reference,
        //     location,
        //     notes
        // };
        const config = {
            method: 'post',
            url: `${baseUrl}/payment?patient=${patient}&amount=${amount}&reference=${reference}&location=${location}&notes=${notes}`,
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        };
        const { data } = await axios.request(config);

        dispatch({
            type: CREATE_PAYMENT_SUCCESS,
            payload: data,
        });
        localStorage.setItem("userInfo", JSON.stringify(data.patient));
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: CREATE_PAYMENT_FAIL,
            payload: message,
        });

    }
};

export const getRecentPayments = (patient) => async (dispatch) => {
    try {
        dispatch({
            type: GET_RECENT_PAYMENT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/payment/recent?patient=${patient}`,
            config
        );

        dispatch({
            type: GET_RECENT_PAYMENT_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_RECENT_PAYMENT_FAIL,
            payload: message,
        });

    }
}
