export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';
export const GET_OTP_REQUEST = 'GET_OTP_REQUEST';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAIL = 'GET_OTP_FAIL';
export const GET_OTP_RESET = 'GET_OTP_RESET';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const LOGOUT_USER = 'LOGOUT_USER'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER_RESET = 'LOGIN_USER_RESET';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';
export const LOGOUT_USER_RESET = 'LOGOUT_USER_RESET';