import React from "react";
import HomeDashboard from "../components/HomeDashboard";

function HomeDashboardScreen() {
  return (
    <div>
      <HomeDashboard />
    </div>
  );
}

export default HomeDashboardScreen;
