import { combineReducers } from "redux";
import { createUserReducer, loginUserReducer, getOTpReducer, verifyOtpReducer, logoutUserReducer } from "./userReducers";
import { bookAppontmentReducer, getAppointmentReducer, getClinicsReducer, getDoctorsReducer } from "./appointmentReducer";
import { createPasswordReducer } from "./createPasswordReducer";
import { createPaymentReducer, getRecentPaymentsReducer } from "./payment";
import { checkAppointment } from "./checkAppointment";

const rootReducer = combineReducers({
  createUser: createUserReducer,
  loginUser: loginUserReducer,
  bookAppontment: bookAppontmentReducer,
  createPassword: createPasswordReducer,
  getOtp: getOTpReducer,
  verifyOtp: verifyOtpReducer,
  createPayment: createPaymentReducer,
  getRecentPayments: getRecentPaymentsReducer,
  getAppointmentAction: getAppointmentReducer,
  getDoctorsAction: getDoctorsReducer,
  getClinicsAction: getClinicsReducer,
  logOutUser: logoutUserReducer,
  checkAppointment: checkAppointment,

});

export default rootReducer;
