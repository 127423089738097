import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import PaymentHome from "./components/Payment/PaymentHome";
import VerifyOtp from "./components/VerifyOtp";
import BookedAppointment from "./screens/appointment/BookedAppointment";
import FirstAppointment from "./screens/appointment/FirstAppointment";
import Online from "./screens/appointment/Online";
import PastAppointment from "./screens/appointment/PastAppointment";
import Payment from "./screens/appointment/Payment";
import UpcomingAppointment from "./screens/appointment/UpcomingAppointment";
import CreatePaaswordScreen from "./screens/CreatePaaswordScreen";
import FirstLoginScreen from "./screens/FirstLoginScreen";
import HomeDashboardScreen from "./screens/HomeDashboardScreen";
import LoginScreen from "./screens/LoginScreen";
import MarkScreen from "./screens/MarkScreen";
import NotificationScreen from "./screens/notification/NotificationScreen";
import OTPscreen from "./screens/OTPscreen";
import PasswordCorrectScreen from "./screens/PasswordCorrectScreen";
import QueueBarcodeScreen from "./screens/queue/QueueBarcodeScreen";
import QueueScreen from "./screens/queue/QueueScreen";
import QueueWaitScreen from "./screens/queue/QueueWaitScreen";
import RegisterScreen from "./screens/RegisterScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import VideoAppointment from "./screens/appointment/VideoAppointment";
import CheckAppointmentDay from "./screens/appointment/CheckAppointmentDay";
import QrLogin from "./screens/QrLogin";
import QrRegister from "./screens/QrRegister";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/login" element={<FirstLoginScreen />} />
      <Route path="/verify" element={<VerifyOtp />} />
      {/* <Route path="/login" element={<LoginScreen />} /> */}
      <Route path="/success" element={<MarkScreen />} />
      <Route path="/otp" element={<OTPscreen />} />
      <Route path="/createpassword" element={<CreatePaaswordScreen />} />
      <Route path="/passwordcorrect" element={<PasswordCorrectScreen />} />
      <Route path="/homedashboard" element={<HomeDashboardScreen />} />
      <Route path="/first-appointment" element={<FirstAppointment />} />
      <Route path="/not-found" element={<NotFoundScreen />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
      {/* Queue routes */}
      <Route path="/queue" element={<QueueScreen />} />
      <Route path="/queue-wait" element={<QueueWaitScreen />} />
      <Route path="/queue-barcode" element={<QueueBarcodeScreen />} />
      <Route path="/notification" element={<NotificationScreen />} />
      <Route path="/appointment" element={<UpcomingAppointment />} />
      <Route path="/appointment-past" element={<PastAppointment />} />
      <Route path="/appointment-booked" element={<BookedAppointment />} />
      <Route path="/appointment-payment" element={<Payment />} />
      <Route path="/appointment-online" element={<Online />} />
      <Route path="/payment" element={<PaymentHome />} />
      <Route path="/appointment/live" element={<VideoAppointment />} />
      <Route path="/appointment/check" element={<CheckAppointmentDay />} />
      <Route path="/qr-login" element={<QrLogin />} />
      <Route path="/qr-register" element={<QrRegister />} />
    </Routes>
  );
}

export default Router;
