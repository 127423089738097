export const BOOK_APPOINTMENT_REQUEST = "BOOK_APPOINTMENT_REQUEST";
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";
export const BOOK_APPOINTMENT_FAIL = "BOOK_APPOINTMENT_FAIL";
export const BOOK_APPOINTMENT_RESET = "BOOK_APPOINTMENT_RESET";
export const GET_APPOINTMENT_REQUEST = "GET_APPOINTMENT_REQUEST";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAIL = "GET_APPOINTMENT_FAIL";
export const GET_APPOINTMENT_RESET = "GET_APPOINTMENT_RESET";
export const GET_DOCTORS_REQUEST = "GET_DOCTORS_REQUEST";
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
export const GET_DOCTORS_FAIL = "GET_DOCTORS_FAIL";
export const GET_DOCTORS_RESET = "GET_DOCTORS_RESET";
export const GET_CLINICS_REQUEST = "GET_CLINICS_REQUEST";
export const GET_CLINICS_SUCCESS = "GET_CLINICS_SUCCESS";
export const GET_CLINICS_FAIL = "GET_CLINICS_FAIL";
export const GET_CLINICS_RESET = "GET_CLINICS_RESET";

