import { CHECK_APPOINTMENT_FAIL, CHECK_APPOINTMENT_REQUEST, CHECK_APPOINTMENT_SUCCESS } from "../constants/checkAppointment";
import { baseUrl } from "./baseUrl";
import axios from "axios";


const config = {
    headers: {
        "Content-Type": "Application/json",
        "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
    },
};
const checkAppointment = (patient) => async (dispatch) => {
    try {
        dispatch({
            type: CHECK_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.get(
            `${baseUrl}/check_appointment?patient=${patient}`,
            config
        );

        dispatch({
            type: CHECK_APPOINTMENT_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message;
        dispatch({
            type: CHECK_APPOINTMENT_FAIL,
            payload: message,
        });
    }
};

export { checkAppointment }
