import React from "react";
import img from "../../assets/booked.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCalendarDate } from "react-icons/bs";
import Button from "../../components/Button/Button";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function BookedAppointment() {
    const store = useSelector(state => state.bookAppontment)
    const appointment = useLocation().state.data;

    return (
        <div className="h-screen">
            <div className="flex flex-col justify-center items-center mt-16">
                <div>
                    <img src={img} alt="" />
                </div>
                <p className="text-4xl font-bold">Congratulations!</p>
                <p className="mx-10 mt-4 mb-4">
                    You have successfully booked an appointment with <b>{appointment.clinic_name}</b>{" "}
                </p>
                <div className="flex justify-between items-center">
                    <div className="flex flex-col items-center border-2 shadow-xl shadow-black-600 w-40 h-40 p-5 mt-8 mx-2">
                        <div className="flex items-center rounded-full bg-red-50 p-4 relative bottom-14">
                            <BsCalendarDate className="text-primary100 text-2xl" />
                        </div>
                        <p className="text-primary100 font-semibold text-xl relative bottom-4">
                            {appointment.appt_date}
                        </p>
                        {/* <p className="text-primary100 font-medium text-xl">January</p> */}
                    </div>
                    <div className="flex flex-col items-center border-2 shadow-xl shadow-black-600 w-40 p-5 mt-8 mx-2 h-40">
                        <div className="flex items-center rounded-full bg-red-50 p-4 relative bottom-14">
                            <AiOutlineClockCircle className="text-primary100 text-2xl" />
                        </div>
                        <p className="text-primary100 font-semibold text-3xl relative bottom-4">
                            {appointment.appt_time}
                        </p>
                    </div>
                </div>
                <div className="mt-12 mb-5 w-100">
                    <Link to={'/appointment'} >
                        <Button
                            text={"See Appointments"}
                            textColor={"text-white"}
                            bg={"bg-primary100"}
                            width={"80"}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
}
